import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  PermIdentity,
  CreditScore,
  ReceiptLong,
  MailOutline,
  Home,
} from "@mui/icons-material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import "./Bell.css";
import { server } from "../../server";
import { toast } from "react-toastify";

export default function Sidebar() {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        setLoading(false);
      }
      setLoading(false);
    };

    fetchBorrowerByToken();
  }, []);

  return (
    <div className="md:block px-2 py-5 md:w-60 lg:w-60  transition-transform duration-300 ease-in-out border shadow-lg rounded-md ">
      {/* LOGO */}
      <div className="my-10">
        <h3 className="text-center text-2xl">Sprouting Storm.</h3>
        <p className="text-center text-sm">Lending Application</p>
        <p className="text-center text-green-700 text-xs">
          Hello, {user?.firstname}
        </p>
      </div>

      <hr className="h-px bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />
      {/* MENU */}
      <div className="my-10">
        {loading ? (
          <>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <Home />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <NotificationsActiveIcon />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <PermIdentity />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <CreditScore />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <ReceiptLong />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <MailOutline />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
            <ul>
              <li className="text-sm flex font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                <LockOpenIcon />
                <Link to="/home" className="ml-2.5">
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                </Link>
              </li>
            </ul>
          </>
        ) : (
          <ul>
            {user?.isAdmin && (
              <>
                <Link to="/home">
                  <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out cursor-pointer">
                    <Home />
                    <Link to="/home" className="ml-2.5">
                      Home
                    </Link>
                  </li>
                </Link>
              </>
            )}
            <Link to="/notifications">
              <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                <NotificationsActiveIcon className="bell" />
                <Link to="/notifications" className="ml-2.5">
                  Notifications
                </Link>
              </li>
            </Link>
            <Link to="/borrowers">
              <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                <PermIdentity />
                <Link to="/borrowers" className="ml-2.5">
                  Borrowers
                </Link>
              </li>
            </Link>
            {/* <Link to="/stk-push">
              <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                <MonetizationOn />
                <Link to="/stk-push" className="ml-2.5">
                  Lipa na Mpesa
                </Link>
              </li>
            </Link> */}
            {user?.isAdmin && (
              <>
                <Link to="/loans">
                  <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                    <CreditScore />
                    <Link to="/loans" className="ml-2.5">
                      Loans
                    </Link>
                  </li>
                </Link>
                <Link to="/payments">
                  <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                    <ReceiptLong />
                    <Link to="/payments" className="ml-2.5">
                      Payments
                    </Link>
                  </li>
                </Link>
                {/* <Link to="/mpesa-payments">
                  <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                    <ReceiptLong />
                    <Link to="/mpesa-payments" className="ml-2.5">
                      Mpesa Payments
                    </Link>
                  </li>
                </Link> */}
                <Link to="/emailClient">
                  <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                    <MailOutline />
                    <Link to="/emailClient" className="ml-2.5">
                      Email
                    </Link>
                  </li>
                </Link>
                <Link to="/resetPassword">
                  <li className="text-sm font-medium text-gray-700 py-2 px-2 hover:bg-red-500 hover:text-white hover:text-base rounded-md transition duration-150 ease-in-out">
                    <LockOpenIcon />
                    <Link to="/resetPassword" className="ml-2.5">
                      Password Reset
                    </Link>
                  </li>
                </Link>
              </>
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
