import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Verification = ({ setAuth }) => {
  const [verificationCode, setVerificationCode] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = localStorage.getItem("userEmail");
    const partiallyObscuredEmail = obscureEmail(storedEmail);
    setUserEmail(partiallyObscuredEmail);
    setEmail(storedEmail);
  }, []);

  const obscureEmail = (email) => {
    const atIndex = email.indexOf("@");
    const firstPart = email.slice(0, atIndex);
    const obscuredFirstPart =
      firstPart.substring(0, 2) + "*".repeat(firstPart.length - 5);
    const secondPart = email.slice(atIndex);
    return obscuredFirstPart + secondPart;
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setVerificationCode(value);
  };

  const resendCode = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${server}/user/resend-code`, {
        email,
      });
      toast.info("Code resent to your email!");
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      } else {
        console.error("Code Failed:", error);
        toast.info(error.message);
      }
    }
  };
  const handleVerification = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${server}/user/verification-code`, {
        verificationCode,
        email,
      });
      if (response.data.message === "Login successful") {
        if (response.data?.isAdmin === true) {
          localStorage.setItem("token", response.data.token);
          navigate(`/home`);
          setAuth(true);
        } else if (response.data?.isSeller === true) {
          localStorage.setItem("token", response.data.token);
          navigate(`/borrowers`);
          setAuth(true);
        } else {
          localStorage.setItem("token", response.data.token);
          navigate(`/`);
          setAuth(true);
        }
        toast.success("Login successful");
        setLoading(false);
      } else {
        toast.info(response.data.message);
        setLoading(false);
      }
      // setAuth(true);
    } catch (error) {
      if (error.message) {
        setLoading(false);
        toast.error(error.message);
      } else {
        setLoading(false);
        console.error("Verification failed:", error);
        toast.info(error.message);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-12">
        <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
          <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
            <div className="flex flex-col items-center justify-center text-center space-y-2">
              <div className="font-semibold text-3xl">
                <p>Email Verification</p>
              </div>
              <div className="flex flex-row text-sm font-medium text-gray-400">
                <p>We have sent a code to your email {userEmail}</p>
              </div>
            </div>

            <div>
              <form onSubmit={handleVerification}>
                <div className="flex flex-col space-y-5">
                  <div>
                    <input
                      className="w-full px-5 py-3 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-red-700"
                      type="text"
                      placeholder="Enter verification code"
                      value={verificationCode}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="w-full py-5 bg-red-700 text-white text-sm rounded-xl shadow-sm"
                    >
                      Verify Account
                    </button>
                  </div>

                  <div
                    onClick={resendCode}
                    className="text-center text-sm font-medium text-gray-500"
                  >
                    <p>Didn't receive code?</p>{" "}
                    <a
                      className="text-red-600"
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Resend
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
