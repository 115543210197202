import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { toast } from "react-toastify";
import { server } from "../../../../server";
import Loader from "../../loader/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";

const Notifications = ({ setAuth }) => {
  const [loans, setLoans] = useState([]);
  const [borrowers, setBorrowers] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const loansResponse = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const borrowersResponse = await fetch(
          `${server}/borrower/get-borrowers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const loansData = await loansResponse.json();
        const borrowersData = await borrowersResponse.json();

        const sortedLoans = loansData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        const borrowerMap = {};
        borrowersData.forEach((borrower) => {
          borrowerMap[borrower._id] = borrower;
        });
        setLoans(sortedLoans);
        setBorrowers(borrowerMap);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
        toast.error("Error fetching Notifications. Login Again");
      }
    };

    fetchBorrowerByToken();
  }, []);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };
  const calculateWeeksElapsed = (dateReleased) => {
    const millisecondsElapsed = Date.now() - new Date(dateReleased);
    return Math.floor(millisecondsElapsed / (7 * 24 * 60 * 60 * 1000));
  };

  const calculateLastPaymentDate = (
    dateReleased,
    terms,
    amort,
    grandAmount,
    balance
  ) => {
    let currentDate = new Date(dateReleased);
    let paidToDateRequired = 0;
    const weeksElapsed = Math.floor(
      (Date.now() - new Date(dateReleased)) / (7 * 24 * 60 * 60 * 1000)
    );

    while (
      paidToDateRequired <= grandAmount - balance &&
      weeksElapsed * amort >= paidToDateRequired
    ) {
      paidToDateRequired += amort;
      currentDate.setDate(currentDate.getDate() + 7);
    }

    return currentDate.toDateString();
  };

  const loanIsOverdue = (loan) => {
    const weeksElapsed = calculateWeeksElapsed(loan.date_released);
    const paidToDateRequired = weeksElapsed * loan.amort;
    const realPaidToDate = loan.grand_amount - loan.balance;
    return paidToDateRequired > realPaidToDate;
  };

  const loanIsUpcomingOverdue = (loan) => {
    const weeksElapsed = calculateWeeksElapsed(loan.date_released);
    const paidToDateRequired = weeksElapsed * loan.amort;
    const realPaidToDate = loan.grand_amount - loan.balance;
    return (
      !loanIsOverdue(loan) && paidToDateRequired + loan.amort > realPaidToDate
    );
  };

  return (
    <div className="flex h-[900px]">
      <Sidebar />

      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Notifications
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              Late loans notifications dues.
            </p>
          </div>
          <div className="text-white">
            <button className="" onClick={handleLogout}>
              <Logout />
            </button>
          </div>
        </div>
        <div className="flex items-center justify-between border-y-2 mt-5">
          <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
            Recent Loan Notifications
          </h3>
        </div>
        <div className="w-full h-[650px] px-4 overflow-auto hover:overflow-scroll mt-5 border rounded shadow-md border-t-4 border-t-red-500 ">
          {loading ? (
            <Loader />
          ) : (
            <table className="table-fixed text-center  ">
              <thead>
                <tr>
                  <th className="w-1/6 px-1 py-2 text-gray-600">Client Name</th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">Loan Type</th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">
                    Outstanding Balance
                  </th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">
                    Amortization
                  </th>
                  <th className="w-1/1 px-1 py-2 text-gray-600">Grand Loan</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Due Since</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">
                    Date Released
                  </th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">
                    Maturity Date
                  </th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Status</th>
                  <th className="w-1/1 px-4 py-2 text-gray-600">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loans.length > 0 &&
                  loans.some((loan) => loanIsOverdue(loan)) && (
                    <React.Fragment>
                      <tr>
                        <th
                          colSpan="10"
                          className="text-lg text-red-400 font-medium leading-6 text-gray my-2 px-1 py-2"
                        >
                          Current Overdue Loans
                        </th>
                      </tr>
                      {loans.map((loan, index) => {
                        if (loanIsOverdue(loan) && loan.balance !== 0) {
                          const lastPaymentDate = calculateLastPaymentDate(
                            loan.date_released,
                            loan.terms,
                            loan.amort,
                            loan.grand_amount,
                            loan.balance
                          );

                          return user?.isSeller === true ? (
                            loan.createrId === user.userId && (
                              <tr key={index}>
                                <td className="border px-4 py-2">
                                  {loading2 ? (
                                    <div className="">
                                      <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                    </div>
                                  ) : (
                                    <>
                                      {borrowers[loan.borrower]?.firstname +
                                        " " +
                                        borrowers[loan.borrower]?.lastname}
                                    </>
                                  )}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  {loan.type}
                                </td>
                                <td className="border px-4 py-2 ">
                                  {loan.balance.toLocaleString()}
                                </td>
                                <td className="border px-4 py-2 ">
                                  {loan.amort.toLocaleString()}
                                </td>
                                <td className="border px-4 py-2 bg-gray-50">
                                  {loan.grand_amount?.toLocaleString()}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  {lastPaymentDate}
                                </td>
                                <td className="border px-4 py-2 ">
                                  {new Date(loan.date_released).toDateString()}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  {new Date(loan.maturity_date).toDateString()}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                                    overdue
                                  </span>
                                </td>
                                <td className="border px-4 py-2">
                                  <Link
                                    to={`/Borrower/${
                                      borrowers[loan.borrower]?._id
                                    }`}
                                  >
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                      <VisibilityIcon className="text-sm" />
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr key={index}>
                              <td className="border px-4 py-2">
                                {loading2 ? (
                                  <div className="">
                                    <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                  </div>
                                ) : (
                                  <>
                                    {borrowers[loan.borrower]?.firstname +
                                      " " +
                                      borrowers[loan.borrower]?.lastname}
                                  </>
                                )}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                {loan.type}
                              </td>
                              <td className="border px-4 py-2 ">
                                {loan.balance.toLocaleString()}
                              </td>
                              <td className="border px-4 py-2 ">
                                {loan.amort.toLocaleString()}
                              </td>
                              <td className="border px-4 py-2 bg-gray-50">
                                {loan.grand_amount?.toLocaleString()}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                {lastPaymentDate}
                              </td>
                              <td className="border px-4 py-2 ">
                                {new Date(loan.date_released).toDateString()}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                {new Date(loan.maturity_date).toDateString()}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                                  overdue
                                </span>
                              </td>
                              <td className="border px-4 py-2">
                                <Link
                                  to={`/Borrower/${
                                    borrowers[loan.borrower]?._id
                                  }`}
                                >
                                  <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                    <VisibilityIcon className="text-sm" />
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </React.Fragment>
                  )}

                {/* Upcoming overdue loans */}
                {loans.length > 0 &&
                  loans.some((loan) => loanIsUpcomingOverdue(loan)) && (
                    <React.Fragment>
                      <tr>
                        <th
                          colSpan="10"
                          className="text-lg text-red-400 font-medium leading-6 text-gray my-2 px-1 py-2"
                        >
                          Upcoming Loans
                        </th>
                      </tr>

                      {loans.map((loan, index) => {
                        if (loanIsUpcomingOverdue(loan)) {
                          const lastPaymentDate = calculateLastPaymentDate(
                            loan.date_released,
                            loan.terms,
                            loan.amort,
                            loan.grand_amount,
                            loan.balance
                          );

                          return user?.isSeller === true ? (
                            loan.createrId === user.userId && (
                              <tr key={index}>
                                <td className="border px-4 py-2">
                                  {loading2 ? (
                                    <div className="">
                                      <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                    </div>
                                  ) : (
                                    <>
                                      {borrowers[loan.borrower]?.firstname +
                                        " " +
                                        borrowers[loan.borrower]?.lastname}
                                    </>
                                  )}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  {loan.type}
                                </td>
                                <td className="border px-4 py-2 ">
                                  {loan.balance.toLocaleString()}
                                </td>
                                <td className="border px-4 py-2 ">
                                  {loan.amort.toLocaleString()}
                                </td>
                                <td className="border px-4 py-2 bg-gray-50">
                                  {loan.grand_amount?.toLocaleString()}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  {lastPaymentDate}
                                </td>
                                <td className="border px-4 py-2 ">
                                  {new Date(loan.date_released).toDateString()}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  {new Date(loan.maturity_date).toDateString()}
                                </td>
                                <td className="border px-4 py-2  bg-gray-50">
                                  <span className=" bg-yellow-400 text-white px-4 py-1 rounded-md">
                                    upcoming
                                  </span>
                                </td>
                                <td className="border px-4 py-2">
                                  <Link
                                    to={`/Borrower/${
                                      borrowers[loan.borrower]?._id
                                    }`}
                                  >
                                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                      <VisibilityIcon className="text-sm" />
                                    </button>
                                  </Link>
                                </td>
                              </tr>
                            )
                          ) : (
                            <tr key={index}>
                              <td className="border px-4 py-2">
                                {loading2 ? (
                                  <div className="">
                                    <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                    <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                  </div>
                                ) : (
                                  <>
                                    {borrowers[loan.borrower]?.firstname +
                                      " " +
                                      borrowers[loan.borrower]?.lastname}
                                  </>
                                )}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                {loan.type}
                              </td>
                              <td className="border px-4 py-2 ">
                                {loan.balance.toLocaleString()}
                              </td>
                              <td className="border px-4 py-2 ">
                                {loan.amort.toLocaleString()}
                              </td>
                              <td className="border px-4 py-2 bg-gray-50">
                                {loan.grand_amount?.toLocaleString()}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                {lastPaymentDate}
                              </td>
                              <td className="border px-4 py-2 ">
                                {new Date(loan.date_released).toDateString()}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                {new Date(loan.maturity_date).toDateString()}
                              </td>
                              <td className="border px-4 py-2  bg-gray-50">
                                <span className=" bg-yellow-400 text-white px-4 py-1 rounded-md">
                                  upcoming
                                </span>
                              </td>
                              <td className="border px-4 py-2">
                                <Link
                                  to={`/Borrower/${
                                    borrowers[loan.borrower]?._id
                                  }`}
                                >
                                  <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                    <VisibilityIcon className="text-sm" />
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </React.Fragment>
                  )}
              </tbody>
            </table>
          )}
        </div>{" "}
      </div>
    </div>
  );
};

export default Notifications;
