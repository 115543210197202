import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import { DeleteForever } from "@mui/icons-material";
import { server } from "../../../server";
import axios from "axios";
import Loader from "../loader/Loader";

const Sellers = ({ setAuth }) => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAdmins = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/user/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const adminsData = {};
        for (const key in data) {
          if (Array.isArray(data[key]) && data[key].length > 0) {
            const adminArray = data[key].filter((user) => user.isSeller);
            if (adminArray.length > 0) {
              adminsData[key] = adminArray;
            }
          }
        }
        setAdmins(adminsData);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchAdmins();
  }, []);

  const deleteUser = async (userId) => {
    try {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete this Admin?"
      );
      if (userConfirmed) {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const loadingToastId = toast.info("Deleting Admin...", {
          autoClose: false,
        });
        await axios.delete(`${server}/user/delete-user`, {
          data: { userId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.dismiss(loadingToastId);

        const response = await fetch(`${server}/user/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        setAdmins(data);
        toast.success("Admin Deleted Successfully");
      } else {
        console.log("Deletion cancelled by the user");
      }
    } catch (error) {
      console.error("Error deleting loan:", error);
    }
  };

  return (
    <div className="w-full h-[500px] border bg-white shadow-md rounded mt-5  border-t-4 border-t-red-500">
      <ToastContainer />
      <div className="py-5 px-5">
        {/* TITLE */}
        <div className="flex items-center justify-between border-b-2">
          <h3 className="text-lg font-medium  text-gray   px-1 ">
            Manage Marketers
          </h3>
          <button className="border hover:bg-red-700 bg-red-500 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline mr-5">
            <Link to="/addMarketer">Add Marketer</Link>
          </button>
        </div>
        {/* INFO */}
        <div className="w-full h-[400px] px-4   mt-5 overflow-auto hover:overflow-scroll border rounded shadow-md ">
          {loading ? (
            <Loader />
          ) : (
            <table className="table-fixed text-center mb-2">
              <thead className=" mt-5">
                <tr className=" mt-10">
                  <th className="w-1/1 px-1 py-2 text-gray-600">ID</th>
                  <th className="w-1/4 px-1 py-2 text-gray-600">Full Name</th>
                  <th className="w-1/4 px-1 py-2 text-gray-600">
                    Contact Number
                  </th>
                  <th className="w-1/4 px-1 py-2 text-gray-600">Email</th>
                  <th className="w-1/4 px-1 py-2 text-gray-600">Status</th>
                  <th className="w-1/1 px-1 py-2 text-gray-600">Delete</th>
                </tr>
              </thead>
              <tbody>
                {admins.length <= 0 ? (
                  <tr className="border px-4 py-2 bg-red-50">
                    <td></td>
                    <td></td>
                    <td className="px-4 py-2 bg-red-50">No Admin Data</td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  admins.users?.map((admin, index) => {
                    return (
                      <>
                        {admin.isSeller && (
                          <tr key={index}>
                            <td className="border px-4 py-2 bg-gray-50">
                              {admin.id}
                            </td>
                            <td className="border px-4 py-2 ">
                              {admin.firstname + " " + admin.secondname}{" "}
                            </td>
                            <td className="border px-4 py-2">{admin.phone}</td>
                            <td className="border px-4 py-2 bg-gray-50">
                              {admin.email}
                            </td>
                            <td className="border px-4 py-2 bg-gray-50">
                              <div className="flex items-center">
                                <div
                                  className={`h-2.5 w-2.5 rounded-full ${
                                    admin.status === "online"
                                      ? "bg-green-500"
                                      : "bg-red-500"
                                  } me-2`}
                                ></div>{" "}
                                {admin.status}{" "}
                              </div>
                            </td>
                            <td className="border px-4 py-2">
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-full text-sm"
                                onClick={() => deleteUser(admin._id)}
                              >
                                <DeleteForever className="text-lg" />
                              </button>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sellers;
