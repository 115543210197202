import React, { useState, useEffect } from "react";
import Sidebar from "../../../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../loader/Loader";
import { server } from "../../../../server";

export default function MpesaPayments({ setAuth }) {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const [limit, setLimit] = useState(10); // Items per page

  const email = user?.email;

  // Helper function to format the date
const formatDate = (dateTimeString) => {
  const year = dateTimeString.slice(0, 4);
  const month = dateTimeString.slice(4, 6) - 1; // Months are zero-indexed
  const day = dateTimeString.slice(6, 8);
  const hours = dateTimeString.slice(8, 10);
  const minutes = dateTimeString.slice(10, 12);
  const seconds = dateTimeString.slice(12, 14);

  const date = new Date(year, month, day, hours, minutes, seconds);
  return date.toLocaleString(); // Format as "12/9/2024, 8:30:00 AM" or similar
};

  // Fetch transaction data from the server
  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          setLoading(false);
          return;
        }

        const response = await fetch(
          `${server}/storm-pay/transactions?page=${page}&limit=${limit}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorMessage = await response.text();
          toast.error(`Error: ${response.status} - ${errorMessage}`);
          setLoading(false);
          return;
        }

        const data = await response.json();
        setTransactions(data.results);
        setTotalPages(data.totalPages); // Set total pages from the response
      } catch (error) {
        console.error("Error fetching transaction data:", error);
        toast.error("Failed to fetch transactions");
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [page, limit]);

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email: user?.email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.error("Error during logout:", error);
      toast.error("Error Occurred!");
    }
  };

  // Pagination handlers
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  return (
    <div className="flex h-[900px]">
      <Sidebar />

      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        {/* Header Section */}
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md">
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Mpesa Payments Report
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              Summary of Loan Repayment Transactions through M-Pesa.
            </p>
          </div>
          <div className="text-white">
            <button onClick={handleLogout}>
              <Logout />
            </button>
          </div>
        </div>

        {/* Transactions Table Section */}
        <div className="mt-5">
          <h3 className="text-lg font-medium leading-6 text-gray-700 mb-4">
            Mpesa Payments Transactions
          </h3>
          <div className="w-full h-[650px] px-4 overflow-auto hover:overflow-scroll mt-5 border rounded shadow-md border-t-4 border-t-red-500">
            {loading ? (
              <Loader />
            ) : (
              <table className="table-fixed text-center w-full">
                <thead>
                  <tr>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Transaction ID
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Client Name
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Amount Paid
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Transaction Date
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Loan Amount
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Outstanding Balance
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.length <= 0 ? (
                    <tr className="border px-4 py-2 bg-red-50">
                      <td colSpan="7" className="px-4 py-2 bg-red-50">
                        No Notifications
                      </td>
                    </tr>
                  ) : (
                    transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2 bg-gray-50">
                          {transaction.transaction_id}
                        </td>
                        <td className="border px-4 py-2">
                          {transaction.client_name}
                        </td>
                        <td className="border px-4 py-2 bg-gray-50">
                          {transaction.amount_paid}
                        </td>
                        <td className="border px-4 py-2">
                          {formatDate(transaction.date)}
                        </td>
                        <td className="border px-4 py-2 bg-gray-50">
                          {transaction.outstanding_balance}
                        </td>
                        <td className="border px-4 py-2">
                          {transaction.new_balance}
                        </td>
                        <td className="border px-4 py-2">
                          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-green-500 hover:text-white">
                            Approve
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            )}
          </div>

          {/* Pagination Controls */}
          <div className="mt-4 flex justify-between items-center">
            <button
              onClick={() => handlePageChange(page - 1)}
              className="px-4 py-2 bg-gray-300 rounded"
              disabled={page === 1}
            >
              Previous
            </button>
            <span>
              Page {page} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(page + 1)}
              className="px-4 py-2 bg-gray-300 rounded"
              disabled={page === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
